import React from 'react'
import Card from '../../components/Card';
import items from './data'
import './prices.css'

const Prices = () => {
  return (
    <section id="prices">
      <h2>Tarifs</h2>
      <h4>Un tarif abordable pour tous les budgets</h4>
      
      <div className="container prices__container" data-aos="fade-up">
        {
          items.map(item => (
            <Card key={item.id} className="price light">
              <div className="price__details">
                <div className='price__titlePrice'>
                <h4 className='price__title'>{item.title}</h4>
                <h3 className='price__price'>{item.price}</h3>
                </div>
                  <p className='price__desc'>{item.desc}</p>
              </div>
            </Card>
          ))
        }
      </div>
    </section>
  )
}

export default Prices