import { useEffect } from 'react'
import data from './data'
import FRAME1 from '../../assets/frame1.png'
import FRAME2 from '../../assets/frame2.jpg'
import FRAME3 from '../../assets/frame3.png'
import FRAME4 from '../../assets/frame4.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './header.css'

const Header = () => {
  useEffect(() => {
    AOS.init({duration: 2000})
  }, [])

  return (
    <header id="header">
      <div className="container header__container">
      <div className="header__frames">
            <div className="header__frame">
            
                <img src={FRAME1} alt="Header Frame One"/>
            </div>
            <div className="header__frame">
                <img src={FRAME2} alt="Header Frame Two"/>
            </div>
        </div>
       
        <h2  data-aos="fade-up">Bienvenue sur sabt.fr</h2>
        <p  data-aos="fade-up">
        Vous souhaitez avoir pour vous ou votre entreprise un site Web ou une application Web, envoyez-moi dès aujourd'hui par mail les détails de votre projet pour un site Web moderne et hautement performant !
        </p>
        <div className="header__cta"  data-aos="fade-up">
          <a href="#contact" className='btn primary'>Contactez-moi</a>
          <a href="#portfolio" className='btn light'>Mes réalisations</a>
        </div>
        <div className="header__socials">
          {
            data.map(item => <a key={item.id} href={item.link} target="_blank" rel="noopener noreferrer">{item.icon}</a>)
          }
        </div>
        <div className="header__frames-deux">
            <div className="header__frame-un">
            
                <img src={FRAME3} alt="Header Frame One"/>
            </div>
            <div className="header__frame-un">
                <img src={FRAME4} alt="Header Frame Two"/>
            </div>
        </div>

        
        
      </div>
    </header>
  )
}

export default Header