
const data = [
    {id: 1, link: '#', title: 'Accueil'},
    {id: 2, link: '#about', title: 'A propos'},
    {id: 3, link: '#services', title: 'Services'},
    {id: 4, link: '#portfolio', title: 'Portfolio'},
    {id: 5, link: '#prices', title: 'Tarifs'},
    {id: 6, link: '#contact', title: 'Contact'}
    ]

    export default data;