const data = [
    {
      id: 1,
      question: "De quoi avez-vous besoin pour commencer à travailler sur mon projet ?",
      answer: "Cela dépend surtout du type de projet. Mais en général, vous avez besoin d'une idée juste de ce que vous voulez, puis nous pouvons partir de là."
    },
    {
      id: 2,
      question: "Combien de temps mon projet prendra-t-il pour être réalisé ?",
      answer: "Cela dépend de la complexité du projet, de votre disponibilité et de votre budget."
    },
    {
      id: 5,
      question: "Est-ce que l'hébergement et l'enregistrement de domaine sont inclus dans votre tarification ?",
      answer: "Non ! L'hébergement et le domaine sont pris en charge séparément. Vous pouvez le budgeter dans le projet ou vous en occuper vous-même si vous savez comment le faire."
    },
    {
      id: 4,
      question: "Combien facturez-vous pour un site Web ou une application?",
      answer: "Cela dépend de votre budget. Mais mes prix sont abordables."
    },
    {
      id: 3,
      question: "Quelles sont les conditions de paiement ?",
      answer: "Le paiement est divisé en 3. Un paiement initial de 30 % pour me lancer une fois que tous les détails du projet sont définis, 40 % une fois le projet terminé et 30 % une fois le projet livré."
    },
    {
      id: 6,
      question: "Et si le projet ne s'avère pas bon ?",
      answer: "Vous ferez partie du processus dès le début. Vous saurez comment se déroule l'ensemble du projet jusqu'à la fin."
    }
  ]

  export default data