import Image1 from '../../assets/project1.jpg'
import Image2 from '../../assets/project2.png'
import Image3 from '../../assets/project3.png'
import Image4 from '../../assets/project4.jpg'
import Image5 from '../../assets/project5.png'
import Image6 from '../../assets/project6.jpg'
import Image7 from '../../assets/project7.jpg'
import Image8 from '../../assets/project8.png'




const data = [
    {
        id: 1,
        category: 'frontend',
        image: Image1,
        title: "Hospital (frontend)",
        desc: "a website for a hospital",
        demo: 'http://egatortutorials.com',
        github: 'http://clinic.sabt.fr/'
    },
  
    {
        id: 2,
        category: 'frontend',
        image: Image2,
        title: "Personal Trainer (Frontend)",
        desc: " a personal Trainer website in Paris (Île-de-France)",
        demo: 'http://egatortutorials.com',
        github: 'https://fit-mass-gravity.com/'
    },
    {
        id: 3,
        category: 'frontend',
        image: Image3,
        title: "Portfolio with reactjs (frontend)",
        desc: "My personal portfolio website",
        demo: 'http://egatortutorials.com',
        github: 'http://reactportfolio.sabt.fr'
    },
   
    {
        id: 4,
        category: 'backend',
        image: Image4,
        title: "Blog with PHP (Backend)",
        desc: "This is the backend Blog website with admin panel ",
        demo: 'http://egatortutorials.com',
        github: 'http://blogphp.sabt.fr/'
    },
    {
        id: 5,
        category: 'frontend',
        image: Image5,
        title: "  Social media (Frontend)",
        desc: " this is a frontend social media website",
        demo: 'http://egatortutorials.com',
        github: 'http://socialmedia.sabt.fr/'
    },
    {
        id: 6,
        category: 'frontend',
        image: Image6,
        title: "A photography portfolio (frontend)",
        desc: "a website for a photography portfolio",
        demo: 'http://egatortutorials.com',
        github: 'http://photographyportfolio.sabt.fr/'
    },
    {
        id: 7,
        category: 'backend',
        image: Image7,
        title: "A rent flats website (backend)",
        desc: "a future symfony rent flats website",
        demo: 'http://egatortutorials.com',
        github: 'http://futurerent.sabt.fr/'
    },
    {
        id: 8,
        category: 'frontend',
        image: Image8,
        title: "A school website (frontend)",
        desc: "this is a frontend part of a school website",
        demo: 'http://egatortutorials.com',
        github: 'http://school.sabt.fr/'
    },
]


export default data