import {SiLinkedin} from 'react-icons/si'
import {AiFillGithub} from 'react-icons/ai'

export const links = [
    {id: 1, link: '#', title: 'Accueil'},
    {id: 2, link: '#about', title: 'A Propos'},
    {id: 3, link: '#services', title: 'Services'},
    {id: 4, link: '#portfolio', title: 'Portfolio'},
    {id: 4, link: '#prices', title: 'Tarifs'},
    {id: 5, link: '#contact', title: 'Contact'}
]


export const socials = [
    {id: 1, link: 'https://www.linkedin.com/in/sa%C3%AFdatou-baba-toherou-b99a81209/', icon: <SiLinkedin/>},
    {id: 2, link: 'https://github.com/Saidatou', icon: <AiFillGithub/>}
]