
import Avatar1 from '../../assets/avatar1.jfif'



const data = [
       
        {
        id: 1,
        quote: "Bon travail, Sa ! J'aime vraiment la façon dont le projet a été fait, et mes clients l'aiment aussi. C'était amusant de collaborer avec toi. Continue de faire du bon travail",
        avatar: Avatar1,
        name: 'Massaoud ou coach Mass',
        profession: 'Coach Sportif'
        },
        
        
        
      
    ]



    export default data