import contacts from './data'
import './contact.css'

const Contact = () => {
  return (
    <section id="contact">
      <h2>Contactez-moi</h2>
      <div className='contact__mailTel container'><p>sbabatoherou@gmail.com</p>
      <p>+33 6 52 76 51 85</p></div>
      <p>
       Envoyez moi un message via l'un des liens ci-dessous!
      </p>
      <div className="container contact__container" data-aos="fade-up">
        {
          contacts.map(contact => <a key={contact.id} href={contact.link} target="_blank" rel="noopener noreferrer">{contact.icon}</a>)
        }
      </div>
    </section>
  )
}

export default Contact