

const data = [
    {
        id: 1,
        title: "Site vitrine WordPress, à partir de :",
        price: " 2000 € HT",
        desc: "Conception de votre site ou application web :  avec le CMS Wordpress avec le thème de votre choix",
        
    },
    
    {
        id: 2,
        title: "Autre sites à partir de : ",
        price: " 3500 € HT",
        desc: "Conception de votre site ou application web sur mesure avec des techologies suivantes : HTML, CSS3, REACT,  SYMFONY, LARAVEL etc",
        
    },
   ,
    
]


export default data