import AboutImage from '../../assets/about.jpg'
import CV from '../../assets/cv.pdf'
import {HiDownload} from 'react-icons/hi'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
  return (
    <section id="about" data-aos="fade-in">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={AboutImage} alt="About Image" />
                </div>
            </div>
            <div className="about__right">
                <h2>A Propos de moi</h2>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                <p>
                Bonjour, je m'appelle Saïdatou BABA-TOHEROU. J’habite à Paris, en France. Je suis développeuse web full-stack depuis octobre 2021 à la suite d’une formation de développement web et web-mobile effectuée au cours de la même année.
                </p>
                <p>
                J’ai appris plusieurs langages dans cette formation que je peux mettre à profit dans la conception de votre site ou application web :  HTML, CSS3, REACT, SYMFONY, LARAVEL, WORDPRESS etc. 

Contactez-moi dès aujourd'hui avec les détails de votre projet! Découvrez mon CV ci-dessous!

                </p>
                <a href={CV} download className='btn primary'>Télécharger CV <HiDownload/></a>
            </div>
        </div>
    </section>
  )
}

export default About