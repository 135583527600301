import {RiReactjsLine} from 'react-icons/ri'
import {FaServer} from 'react-icons/fa'



const data = [
    
    {
        id: 1, icon: <RiReactjsLine/>, title: 'Frontend Development', desc: "Votre site web sera accessible sur tous les appareils, y compris les téléphones portables, les tablettes et les ordinateurs de bureau."
    },
    {
        id: 2, icon: <FaServer/>, title: 'Backend Development', desc: " Vous aurez la possibilité d'intégrer dynamiquement vous-même des données sur votre votre site à l'attention de vos clients. "
    },
   
]


export default data